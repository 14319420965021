/* @import url('//fonts.googleapis.com/css?family=Open+Sans:400,600,700,300'); */
$font-family-sans-serif:    'Helvetica Neue', HelveticaNeue, Helvetica, sans-serif;
$font-family-headline:      'Open sans', sans-serif;

$headings-font-family:                      $font-family-headline;
$headings-font-weight:                      600;
$font-size-base:                            14px;
$font-size-large:                           ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:                           $font-size-base - 1px; // ~13px
$font-size-h1:                              floor(($font-size-base * 1.7)); // ~23px
$font-size-h2:                              floor(($font-size-base * 1.3)); // ~18px
$font-size-h3:                              ceil(($font-size-base * 1.14)); // ~16px
$font-size-h4:                              $font-size-base;
$font-size-h5:                              ceil(($font-size-base * 0.85)); // ~12px
$font-size-h6:                              ceil(($font-size-base * 0.79)); // ~11px

$link-color:                                darken($brand-primary, 10%);
$text-color:                                $gray-base;

$line-height-base:                          1.57142857;
$line-height-computed:                      floor(($font-size-base * $line-height-base)); // ~22px

$headings-line-height:                      1.375;

$grid-gutter-width:                         30px;
$grid-gutter-width-lg:                      $grid-gutter-width + 10px;
$grid-gutter-width-half:                    ceil($grid-gutter-width / 2);

$navbar-height:                             43px;
$navbar-margin-bottom:                      0;
$navbar-border-radius:                      0;
$navbar-default-bg:                         $gray-lightest;
$navbar-default-border:                     $gray-light;
$navbar-default-link-hover-color:           $gray-base;
$navbar-default-link-active-color:          #fff;
$navbar-default-link-active-bg:             $brand-primary;

$table-bg-accent:                           $gray-lightest;
$table-bg-hover:                            $gray-lighter;
$table-border-color:                        $gray-light;
$table-cell-padding:                        8px;

$component-active-color:                    #fff;
$component-active-bg:                       $brand-primary;

$panel-bg:                                  $gray-lightest;
$panel-default-border:                      $gray-light;
$panel-default-heading-bg:                  $gray-lighter;
$panel-default-text:                        $gray-base;
$panel-border-radius:                       0;

$list-group-bg:                             $gray-lightest;
$list-group-border:                         $gray-light;
$list-group-border-radius:                  0;
$list-group-hover-bg:                       $gray-lighter;
$list-group-active-color:                   $component-active-color;
$list-group-active-bg:                      $component-active-bg;
$list-group-active-border:                  $list-group-active-bg;

$tooltip-color:                             $text-color;
$tooltip-bg:                                $gray-lightest;
$tooltip-opacity:                           1;
$tooltip-arrow-color:                       $brand-primary;

$well-bg:                                   $gray-lightest;
$well-border:                               $gray-light;

$input-border-radius:                       0;
$input-color:                               $text-color;
$input-height-base:                         28px;
$input-border:                              #bbb;

$card-border:                               1px solid $gray-light;
$card-border-radius:                        3px;
$card-box-shadow:                           0 2px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.1);

$content-bg:                                #fcfcfc;

$brand-primary-dark:darken($brand-primary, 10%);
$brand-secondary-dark:darken($brand-secondary, 10%);