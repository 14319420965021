div#content div.listing-card.panel.panel-default div.panel-heading {
    background: #fff;
}

div#content div.listing-card.panel.panel-default div.panel-heading h3 {
    font-size: $font-size-h3;
}

div#content div.listing-card.panel.panel-default div.panel-heading i {
    font-size: 25px;
    float: right;
}

div#content div.listing-card.panel.panel-default div.panel-body {
    padding: 0;
    font-size: 12px;
}

div#content div.listing-card.panel.panel-default div.panel-body i {
    margin-right: 5px;
}

div#content div.listing-card.panel.panel-default div.panel-body .media-left {
    padding: 0;
    border-right: 1px solid $gray-light;
    padding: $grid-gutter-width-half 0;
}

div#content div.listing-card.panel.panel-default div.panel-body img {
    width: 170px;
    border-bottom: 0;
}

div#content div.listing-card.panel.panel-default div.panel-body .media-body {
    padding: $grid-gutter-width-half;
}

div#content div.listing-card.panel.panel-default div.panel-body ul {
    padding-left: 0;
    margin-bottom: 0;
}

div#content div.listing-card.panel.panel-default div.panel-body li:last-child {
    margin-bottom: 0;
}


/* Very small devices (mobile portrait) */

@media (max-width: $screen-xs) {
    div#content div.listing-card.panel.panel-default div.panel-body img {
        margin-left: auto;
        margin-right: auto;
    }
    div#content div.listing-card.panel.panel-default div.panel-body strong {
        font-weight: 600;
    }
    div#content div.listing-card.panel.panel-default div.panel-body .media-left {
        border-bottom: 1px solid $gray-light;
        border-right: 0;
        display: inherit;
    }
    div#content div.listing-card.panel.panel-default div.panel-body .media-body {
        width: 100%;
    }
}
