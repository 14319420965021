/* modal-styles */

.modal-header {
    background-color: $brand-primary;
    color: #fff;
}

.modal div.spinner {
    position: fixed;
    z-index: 999;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 70px;
    width: 70px;
}

.modal-body .panel-info {
    border-color: #fff;
}

.modal-body .panel {
    margin-bottom: 0;
    background-color: #fff;
    border: 0;
    border-radius: 0 0 3px 3px;
    box-shadow: none;
}

.modal-body .form-control {
    padding: 6px;
    height: 36px;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.modal-body textarea.form-control {
    height: auto;
}

.modal-body .form-group {
    margin-left: 0;
    margin-right: 0;
}

.modal-header button.close {
    color: #fff;
    opacity: 100;
}

.modal-header button.close:hover,
.modal-header button.close:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    opacity: 100;
    filter: alpha(opacity=0);
}


/* alert-styles */

.modal .alert {
    text-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    margin: -15px -15px 21px;
}

.modal .alert-danger {
    background-image: none;
}
