/* Import theme bootstrap variables */

@import "theme-variables";

/* Import Bootstrap CSS */

@import "bootstrap-custom";
@import "bootstrap/theme";
@import "bootstrap-select.min";

/* Import Iconfont */

@import "icons";

/* Import theme styles */

@import "lead-form";
@import "navigation";
@import "content";
@import "modal";
@import "glossary";
@import "company-listing";
@import "company-profil";
@import "posts-listing";
@import "sales-contact";

/* Import theme snippets */

@import "snippets";

/* general style */

strong {
    font-weight: $headings-font-weight;
}

blockquote p {
    font-style: italic;
    font-size: $font-size-small;
}

blockquote footer {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    text-align: left;
}

blockquote footer cite {
    font-style: normal;
    color: lighten($text-color, 20%);
    font-size: $font-size-small;
}

a {
    color: $brand-primary;
}

a:hover,
a:focus {
    color: $gray-base;
    text-decoration: none;
}

.text-muted {
    color: #999;
}

input, textarea {
    font-size: 16px !important;
}


/* grid styles elements */

body {
    background-color: #fcfcfc;
}

header {
    border-top: 4px $brand-primary solid;
    padding-top: $grid-gutter-width-half;
    padding-bottom: $grid-gutter-width-half;
    background-color: #fff;
}

div#content {
    margin-top: $grid-gutter-width-lg;
    background-color: #fcfcfc;
}

main > .container {
    padding-top: 1px;
}

div#lead {
    margin: 0 0 $grid-gutter-width 0;
}

div#sidebar {
    margin-top: $grid-gutter-width;
}

footer {
    font-size: $font-size-small;
    background-color: $gray-lighter;
    border-top: 1px solid $gray-light;
    margin-top: $grid-gutter-width-lg;
    text-align: center;
}


/* header styles */

div#logo {
    text-align: center;
}

div#logo img {
    height: 35px;
}

div#steps {
    font-size: $font-size-small;
    font-weight: 300;
    line-height: 120%;
    color: $gray-light;
}

div#steps div,
div#steps div.step span,
div#steps div.step p {
    display: inline;
    float: left;
    margin-bottom: 0px;
}

div#steps div {
    margin-right: 4%;
}

div#steps div.step span.circle {
    border: 0px;
    color: $component-active-color;
    background-color: $gray-light;
    margin-right: 10px;
    font-weight: 400;
}

div#steps div.step.active span.circle {
    border: 1px solid $brand-primary;
    color: $component-active-color;
    background-color: $brand-primary
}

div#steps div.step.active p {
    color: $brand-primary;
    margin-bottom: 0px;
}


/* key visual, slogan styles */

div#keyvisual {
    background: url("/img/key-visual-mobile.jpg") no-repeat 0px 0px;
}

div#slogan .container {
    padding: 0;
}

div#slogan p {
    font-family: $headings-font-family;
    font-size: 20px;
    line-height: 1.2;
    padding: 10px $grid-gutter-width-half 10px 11px;
    background: #fff;
    font-weight: 600;
    margin: 100px 0 10px 0;
    border-left: 4px solid $brand-primary;
    color: $brand-primary;
    display: table;
}

div#slogan span {
    font-family: $headings-font-family;
    font-size: 20px;
    line-height: 1.2;
    padding: 10px $grid-gutter-width-half 10px $grid-gutter-width-half;
    background: #fff;
    font-weight: 300;
    margin: 0px 0 100px;
    display: table;
}

div#slogan strong {
    color: #fff;
}


/* button styles */

.btn-primary {
    background-color: $brand-primary;
    background-image: none;
    border-radius: 3px;
    text-shadow: none;
    border: 0;
}

.btn-primary:hover {
    background-color: darken($brand-primary, 3%);
}

.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
    background-color: $brand-primary;
    color: #fff;
}

.btn-default {
    background-color: $gray-light;
    background-image: none;
    border-radius: 3px;
    text-shadow: none;
    border: 0;
}

.btn-default:hover {
    background-color: $brand-primary;
    color: #fff;
}


/* label styles */

.label {
    font-weight: 400;
    line-height: 1;
}

.label-default {
    color: $gray-base;
}


/* footer styles */

footer div.fb-like-box {
    background: $gray-lightest;
    border: 1px solid $gray-light;
    width: 100%;
}

footer .container {
    margin-bottom: $grid-gutter-width-lg;
}

footer .container:last-child {
    margin-bottom: 0;
}

footer .main-footer {
    margin-top: $grid-gutter-width-lg;
}

footer .sub-footer {
    background-color: $gray-light;
    padding: $grid-gutter-width-half 0;
}

footer .sub-footer ul,
footer .sub-footer p {
    margin: 0;
}

footer li:first-child {
    padding-left: 0;
}

footer ul.list-inline {
    margin-left: 0;
}

footer h1,
footer h2,
footer h3,
footer h4,
{
    margin: 0 0 20px 0;
}

footer .sub-footer ul.social-icons {
    margin-top: $grid-gutter-width-half;
}

footer ul.social-icons a {
    color: $brand-primary;
}

footer ul.social-icons a:hover {
    color: darken($brand-primary, 3%);
}

div#ekomi img {
    height: 65px;
}

div#ekomi .ekomi-stars {
    margin-left: auto;
    margin-right: auto;
    max-width: 325px;
}

div#ekomi .media-body {
    vertical-align: middle;
    text-align: left;
}

div#ekomi ul.list-inline,
div#ekomi p {
    margin-bottom: 0px;
}

div#ekomi p {
    line-height: 1;
}

div#ekomi .average {
    font-size: 2em;
    color: $brand-primary;
    font-weight: 500;
}

.big {
    font-size: larger;
}

div#ekomi .progress {
    height: 18px;
    margin-bottom: 0px;
    overflow: hidden;
    background-color: $gray-light;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    background-image: none;
}

div#ekomi .progress-bar {
    float: left;
    width: 0;
    min-width: 2em;
    height: 100%;
    font-size: $font-size-h5;
    line-height: 19px;
    text-align: left;
    padding-left: 7px;
    color: #fff;
    text-align: left;
    background-color: $brand-primary;
    background-image: none;
}

div#ekomi .rate-spread {
    margin-top: 20px;
}

div#ekomi .rate-spread ul.list-inline {
    margin-top: 3px;
}

div#ekomi .rate-spread li:last-child {
    margin-right: 5px;
}

div#ekomi .rate-spread li {
    padding-left: 1px;
    padding-right: 1px;
}

div#ekomi .rate-spread table {
    margin-bottom: 0px;
}

div#ekomi .rate-spread td {
    padding: 5px 10px;
    line-height: 0;
}

div#ekomi ul {
    margin-bottom: 0;
}

div#ekomi ul li {
    margin-bottom: 0;
}

div#ekomi ul.list-inline {
    padding-left: 0;
}

footer .nav-pills.nav-stacked>li>a {
    padding: 5px 0 5px 0;
    border-bottom: 0;
}

footer .nav-pills.nav-stacked>li>a:first-child {
    padding-top: 0px;
}

footer .nav-pills.nav-stacked>li:last-child a {
    padding: 0px;
    margin: 0;
}


/* Homepage */

div#partner {
    margin-bottom: $grid-gutter-width;
}

div#partner .list-inline {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    padding-top: $grid-gutter-width;
    border-top: 1px solid $gray-light;
    opacity: 0.8;
}

div#pros {
    padding: $grid-gutter-width-half 0 $grid-gutter-width 0;
}

div#pros span.circle.check {
    border: 0;
    background-color: #fff;
    display: inline;
    float: none;
    padding: 4px 6px 4px 6px;
    margin-right: 10px;
}

div#pros p {
    color: #fff;
    text-transform: uppercase;
    display: inline;
}

div#pros p strong {
    color: $component-active-color;
}


/* custom elements */

span.circle {
    font-size: $font-size-h2;
    display: block;
    float: left;
    padding: 7px 10px 7px 10px;
    border-radius: 16px;
    border: 1px solid $gray-light;
    color: $brand-primary;
}

span.circle.check {
    padding: 1px 6px 0px 7px;
}

span.circle.check i {
    color: $brand-primary;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}


/* Project Media Queries */


/* Very small devices (mobile portrait) */

@media (max-width: $screen-xs) {
    strong {
        font-weight: 600;
    }
    header {
        padding-top: $grid-gutter-width-half;
    }
    div#slogan p {
        display: inherit;
        margin-bottom: 0px;
        margin-top: 195px;
    }
    div#slogan span {
        display: inherit;
        margin-bottom: $grid-gutter-width-half;
        border-left: 4px solid $brand-primary;
        padding-left: 11px;
        padding-top: 0px;
    }
    div#logo img {
        height: 30px;
    }
    div#ekomi .ekomi-stars {
        max-width: 100%;
    }
    div#ekomi img {
        height: 58px;
    }
    div#ekomi span.label {
        margin-top: 10px;
        display: table;
    }
    div#ekomi .average {
        font-size: 1.5em;
    }
    div#ekomi .icon-2x {
        font-size: 1.5em;
    }
}


/* Small devices (tablets, 768px and up) */

@media (min-width: $screen-sm) {
    div#lead {
        background-color: transparent;
        border: 0;
    }
}


/* Medium devices (desktops, 992px and up) */

@media (min-width: $screen-md) {
    div#lead {
        margin: 0 0 0 0;
        background-color: $gray-lighter;
    }
    div#keyvisual {
        background: url("/img/key-visual.jpg") no-repeat 0px 0px;
        background-position: center;
        background-size: cover;
    }
    header {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    div#slogan p {
        font-size: 28px;
        margin: 0px 0 10px 0;
        margin-top: 100px;
    }
    div#slogan span {
        font-size: 20px;
        margin: 0px 0 50px 0;
    }
    div#steps {
        padding: 3px 0px 0 0px;
    }
    div#sidebar {
        margin-top: 0;
    }
    div#logo {
        text-align: left;
    }
    footer {
        text-align: left;
    }
    footer h1,
    footer h2,
    footer h3,
    footer h4,
    {
        padding-bottom: 7px;
        border-bottom: 1px solid $gray-light;
    }
    footer .sub-footer ul.social-icons {
        float: right !important;
        margin-top: 0;
    }
    footer .sub-footer ul.social-icons {
        float: right !important;
        margin-top: 0;
    }
    div#ekomi .ekomi-stars {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 100%;
    }
}


/* Large devices (large desktops, 1200px and up) */

@media (min-width: $screen-lg) {
    div#slogan p {
        font-size: 30px;
        width: auto;
    }
    div#slogan span {
        font-size: 24px;
    }
    div#steps div {
        margin-right: 7%;
    }
}
