@font-face {
	font-family: "iconfont-1591098900723";
	src: url('../fonts/iconfont-1591098900723.eot');
	src: url('../fonts/iconfont-1591098900723.eot?#iefix') format('eot'),
		url('../fonts/iconfont-1591098900723.woff2') format('woff2'),
		url('../fonts/iconfont-1591098900723.woff') format('woff'),
		url('../fonts/iconfont-1591098900723.ttf') format('truetype'),
		url('../fonts/iconfont-1591098900723.svg#iconfont-1591098900723') format('svg');
}

@mixin icon-styles {
	font-family: "iconfont-1591098900723";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == coffee-mashine {
		$char: "\E001";
	}
	@if $filename == cup {
		$char: "\E002";
	}
	@if $filename == espresso_logo_zweifarbig {
		$char: "\E003";
	}
	@if $filename == jug {
		$char: "\E004";
	}
	@if $filename == store {
		$char: "\E005";
	}
	@if $filename == angle-double-left {
		$char: "\E006";
	}
	@if $filename == angle-double-right {
		$char: "\E007";
	}
	@if $filename == angle-down {
		$char: "\E008";
	}
	@if $filename == angle-left {
		$char: "\E009";
	}
	@if $filename == angle-right {
		$char: "\E00A";
	}
	@if $filename == angle-up {
		$char: "\E00B";
	}
	@if $filename == arrow-right {
		$char: "\E00C";
	}
	@if $filename == bed {
		$char: "\E00D";
	}
	@if $filename == bulb-o {
		$char: "\E00E";
	}
	@if $filename == bulb {
		$char: "\E00F";
	}
	@if $filename == buy {
		$char: "\E010";
	}
	@if $filename == calendar {
		$char: "\E011";
	}
	@if $filename == caret-down {
		$char: "\E012";
	}
	@if $filename == caret-up {
		$char: "\E013";
	}
	@if $filename == check-circle {
		$char: "\E014";
	}
	@if $filename == check {
		$char: "\E015";
	}
	@if $filename == chevron-circle-right {
		$char: "\E016";
	}
	@if $filename == chevron-down {
		$char: "\E017";
	}
	@if $filename == chevron-left {
		$char: "\E018";
	}
	@if $filename == chevron-right {
		$char: "\E019";
	}
	@if $filename == chevron-up {
		$char: "\E01A";
	}
	@if $filename == coffee-steam {
		$char: "\E01B";
	}
	@if $filename == comment {
		$char: "\E01C";
	}
	@if $filename == contract {
		$char: "\E01D";
	}
	@if $filename == envelope {
		$char: "\E01E";
	}
	@if $filename == exclamation-circle {
		$char: "\E01F";
	}
	@if $filename == external {
		$char: "\E020";
	}
	@if $filename == facebook-circle {
		$char: "\E021";
	}
	@if $filename == facebook-official {
		$char: "\E022";
	}
	@if $filename == facebook {
		$char: "\E023";
	}
	@if $filename == fax {
		$char: "\E024";
	}
	@if $filename == fitness {
		$char: "\E025";
	}
	@if $filename == food-tablet {
		$char: "\E026";
	}
	@if $filename == globe {
		$char: "\E027";
	}
	@if $filename == google-plus-circle {
		$char: "\E028";
	}
	@if $filename == google-plus {
		$char: "\E029";
	}
	@if $filename == home {
		$char: "\E02A";
	}
	@if $filename == info-circle {
		$char: "\E02B";
	}
	@if $filename == info {
		$char: "\E02C";
	}
	@if $filename == lock {
		$char: "\E02D";
	}
	@if $filename == logo-ormigo {
		$char: "\E02E";
	}
	@if $filename == long-arrow-right {
		$char: "\E02F";
	}
	@if $filename == map-marker {
		$char: "\E030";
	}
	@if $filename == minus-circle {
		$char: "\E031";
	}
	@if $filename == minus {
		$char: "\E032";
	}
	@if $filename == phone {
		$char: "\E033";
	}
	@if $filename == pinterest-circle {
		$char: "\E034";
	}
	@if $filename == plus-circle {
		$char: "\E035";
	}
	@if $filename == plus {
		$char: "\E036";
	}
	@if $filename == question-circle {
		$char: "\E037";
	}
	@if $filename == question {
		$char: "\E038";
	}
	@if $filename == refresh {
		$char: "\E039";
	}
	@if $filename == search {
		$char: "\E03A";
	}
	@if $filename == spinner {
		$char: "\E03B";
	}
	@if $filename == star-half-o {
		$char: "\E03C";
	}
	@if $filename == star-o {
		$char: "\E03D";
	}
	@if $filename == star {
		$char: "\E03E";
	}
	@if $filename == time {
		$char: "\E03F";
	}
	@if $filename == times-circle {
		$char: "\E040";
	}
	@if $filename == times {
		$char: "\E041";
	}
	@if $filename == twitter-circle {
		$char: "\E042";
	}
	@if $filename == twitter {
		$char: "\E043";
	}
	@if $filename == xing-circle {
		$char: "\E044";
	}
	@if $filename == youtube-play {
		$char: "\E045";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-coffee-mashine {
	@include icon(coffee-mashine);
}
.icon-cup {
	@include icon(cup);
}
.icon-espresso_logo_zweifarbig {
	@include icon(espresso_logo_zweifarbig);
}
.icon-jug {
	@include icon(jug);
}
.icon-store {
	@include icon(store);
}
.icon-angle-double-left {
	@include icon(angle-double-left);
}
.icon-angle-double-right {
	@include icon(angle-double-right);
}
.icon-angle-down {
	@include icon(angle-down);
}
.icon-angle-left {
	@include icon(angle-left);
}
.icon-angle-right {
	@include icon(angle-right);
}
.icon-angle-up {
	@include icon(angle-up);
}
.icon-arrow-right {
	@include icon(arrow-right);
}
.icon-bed {
	@include icon(bed);
}
.icon-bulb-o {
	@include icon(bulb-o);
}
.icon-bulb {
	@include icon(bulb);
}
.icon-buy {
	@include icon(buy);
}
.icon-calendar {
	@include icon(calendar);
}
.icon-caret-down {
	@include icon(caret-down);
}
.icon-caret-up {
	@include icon(caret-up);
}
.icon-check-circle {
	@include icon(check-circle);
}
.icon-check {
	@include icon(check);
}
.icon-chevron-circle-right {
	@include icon(chevron-circle-right);
}
.icon-chevron-down {
	@include icon(chevron-down);
}
.icon-chevron-left {
	@include icon(chevron-left);
}
.icon-chevron-right {
	@include icon(chevron-right);
}
.icon-chevron-up {
	@include icon(chevron-up);
}
.icon-coffee-steam {
	@include icon(coffee-steam);
}
.icon-comment {
	@include icon(comment);
}
.icon-contract {
	@include icon(contract);
}
.icon-envelope {
	@include icon(envelope);
}
.icon-exclamation-circle {
	@include icon(exclamation-circle);
}
.icon-external {
	@include icon(external);
}
.icon-facebook-circle {
	@include icon(facebook-circle);
}
.icon-facebook-official {
	@include icon(facebook-official);
}
.icon-facebook {
	@include icon(facebook);
}
.icon-fax {
	@include icon(fax);
}
.icon-fitness {
	@include icon(fitness);
}
.icon-food-tablet {
	@include icon(food-tablet);
}
.icon-globe {
	@include icon(globe);
}
.icon-google-plus-circle {
	@include icon(google-plus-circle);
}
.icon-google-plus {
	@include icon(google-plus);
}
.icon-home {
	@include icon(home);
}
.icon-info-circle {
	@include icon(info-circle);
}
.icon-info {
	@include icon(info);
}
.icon-lock {
	@include icon(lock);
}
.icon-logo-ormigo {
	@include icon(logo-ormigo);
}
.icon-long-arrow-right {
	@include icon(long-arrow-right);
}
.icon-map-marker {
	@include icon(map-marker);
}
.icon-minus-circle {
	@include icon(minus-circle);
}
.icon-minus {
	@include icon(minus);
}
.icon-phone {
	@include icon(phone);
}
.icon-pinterest-circle {
	@include icon(pinterest-circle);
}
.icon-plus-circle {
	@include icon(plus-circle);
}
.icon-plus {
	@include icon(plus);
}
.icon-question-circle {
	@include icon(question-circle);
}
.icon-question {
	@include icon(question);
}
.icon-refresh {
	@include icon(refresh);
}
.icon-search {
	@include icon(search);
}
.icon-spinner {
	@include icon(spinner);
}
.icon-star-half-o {
	@include icon(star-half-o);
}
.icon-star-o {
	@include icon(star-o);
}
.icon-star {
	@include icon(star);
}
.icon-time {
	@include icon(time);
}
.icon-times-circle {
	@include icon(times-circle);
}
.icon-times {
	@include icon(times);
}
.icon-twitter-circle {
	@include icon(twitter-circle);
}
.icon-twitter {
	@include icon(twitter);
}
.icon-xing-circle {
	@include icon(xing-circle);
}
.icon-youtube-play {
	@include icon(youtube-play);
}
