i[class^="icon-"] {
  display: inline-block;
  line-height:1em;
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  speak: none;
  text-decoration: inherit;
  width: 1em;
  text-align: center; 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none; 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
}

.icon-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -15%;
}

.icon-2x {
  font-size:2em;
}

.icon-3x {
  font-size:3em;
}

.icon-4x {
  font-size:4em;
}

.icon-5x {
  font-size:5em;
}

/* Fixed width */
.icon-fw {
  width: (18em / 14);
  text-align: center;
}

/* Icon List */

$icon-li-width: (30em / 14);

.icon-ul {
  padding-left: 0 !important;
  margin-left: $icon-li-width;
  list-style-type: none;
}

.icon-ul > li {
  position: relative;
}

.icon-li {
  position: absolute;
  left: -$icon-li-width;
  width: $icon-li-width;
  text-align: center;
}

.icon-li .icon-lg {
  left: -$icon-li-width + (4em / 14);
}

/*
   Animation example, for spinners
*/
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}