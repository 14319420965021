/* main navigation */

div#main-navigation {
    background-color: inherit;
}

.navbar {
    border: 0px;
}

.navbar-default {
    box-shadow: none;
    background-image: none;
    background-color: $brand-primary;
    border-color: $brand-primary;
}

.navbar-default ul.navbar-nav {
    margin: 0 ($grid-gutter-width-half * -1);
}

.navbar-default ul.navbar-nav > li > a {
    padding: 10px 20px 10px 20px;
    font-family: $font-family-headline;
    background-image: none;
}

.navbar-default .navbar-nav>.active>a {
    background-image: none;
    background-repeat: no-repeat;
    filter: none;
    box-shadow: none;
    background-color: $brand-primary-dark;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    background-color: $brand-primary-dark;
}

.navbar-default .navbar-nav>li>a {
    color: #fff;
}

.navbar-brand,
.navbar-nav>li>a {
    text-shadow: none;
}

.navbar-nav>li>a {
    line-height: 23px;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
    color: #fff;
    background-color: $brand-primary-dark;
}


/* sub Navigation */

div#content div.list-group .list-group-item {
    margin-bottom: 0;
    background-color: #fff;
    border: 0;
}

div#content div.list-group a.list-group-item {
    padding-left: 10px;
    background-image: none;
    border-bottom: 1px solid $gray-light;
    filter: none;
}

div#content div.list-group a.list-group-item i {
    margin-right: 10px;
}

a.list-group-item:hover,
a.list-group-item:focus {
    background-color: $gray-lightest !important;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
    text-shadow: none;
    border: 0;
    background-color: $brand-primary !important;
}

.list-group-item:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}


/* navbar-fixed-top */

.navbar-fixed-top {
    box-shadow: none;
    background-image: none;
    background-color: #fff;
    border-color: #fff;
    padding: 20px 0;
    border-bottom: 1px solid $gray-light;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-top: 4px $brand-primary solid;
}

.navbar-fixed-top .btn-active:hover,
.navbar-fixed-top .btn-active:active,
.navbar-fixed-top .btn-active:focus {
    background-color: $brand-secondary-dark;
    outline: 0;
}

.navbar-fixed-top .btn-default:hover,
.navbar-fixed-top .btn-default:active,
.navbar-fixed-top .btn-default:focus {
    background-color: $gray-light;
    outline: 0;
}

.navbar-fixed-top .btn-action,
.navbar-fixed-top .btn-default {
    box-shadow: none;
}

.navbar-fixed-top .btn-action i,
.navbar-fixed-top .btn-default i {
    margin: 0;
}

.navbar-fixed-top .btn-default {
    background-image: none;
    background-color: $gray-lightest;
    color: $gray-base;
    text-shadow: none;
    border: 1px solid $gray-light;
    border-radius: 4px;
}

.navbar-fixed-top div#logo {
    text-align: left;
}

.navbar-fixed-top div#logo img {
    height: 35px;
}

.navbar-fixed-top p {
    font-size: 17px;
    color: $gray-base;
    margin: 6px 0 0;
}


/* document Pagination */

div#content ul.pager {
    padding-left: 0;
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
}

div#content div#main ul.pager li.previous,
div#content div#main ul.pager li.next {
    left: 0;
    margin-right: 0;
}

div#content ul.pager li > a,
div#content ul.pager li > a:hover,
div#content ul.pager li > a:active,
div#content ul.pager li > a:visited {
    padding: 0;
    border: 0;
    background: transparent;
}

div#content div.list-group.document-pagination a {
    font-size: $font-size-small;
    padding: $padding-base-vertical $padding-base-horizontal;
}


/* footer navigation */

footer .nav-pills.nav-stacked>li>a {
    padding-left: 0;
    border-bottom: 1px solid $gray-light;
}

footer .nav-pills.nav-stacked>li:last-child>a {
    border-bottom: 0;
    margin-bottom: 10px;
}


/* Very small devices (mobile portrait) */

@media (max-width: $screen-xs) {
    .navbar-fixed-top div#logo img {
        height: 25px;
        margin-top: 6px;
    }
}


/* Small devices (tablets, 768px and up) */

@media (min-width: $screen-sm) {
    div#main-navigation {
        background-color: $brand-primary;
    }
}


/* Medium devices (desktops, 992px and up) */

@media (min-width: $screen-md) {
    .navbar-fixed-top div#logo img {
        margin-top: 0;
        text-align: left;
    }
}
