/* key visual, slogan styles */

div#keyvisual-sales {
    background: url("/img/key-visual-sales-mobile.jpg") no-repeat 0px 0px;
    margin-top: 34px;
}

div#slogan-sales p,
div#slogan-sales span {
    font-family: $headings-font-family;
    font-size: 20px;
    line-height: 1.2;
    background: rgba(255, 255, 255, 0.9);
    border-left: 3px solid $brand-primary;
    display: table;
}

div#slogan-sales p {
    padding: 10px $grid-gutter-width-half 10px 11px;
    font-weight: $headings-font-weight;
    margin: 100px 0 10px 0;
    color: $brand-primary;
}

div#slogan-sales span {
    padding: 10px $grid-gutter-width-half 10px $grid-gutter-width-half;
    margin: 0px 0 100px;
}

div#slogan-sales .card-bg {
    background: $component-active-color !important;
    border-radius: $card-border-radius !important;
    box-shadow: $card-box-shadow !important;
    border: $card-border !important;
}

.arrow {
    margin-bottom: -12%;
    margin-top: 7%;
}


/* interferer */

div#slogan-sales p.interferer {
    float: right;
    color: $component-active-color;
    height: 93px;
    width: 93px;
    margin-top: -39px;
    margin-bottom: -39px;
    border-radius: 50px;
    border: 2px solid $component-active-color;
    text-transform: uppercase;
    padding: 19px 5px 17px 6px;
    background-color: $brand-secondary;
    box-shadow: $card-box-shadow;
    font-size: $font-size-base;
    font-weight: 500;
    line-height: 1.3;
    overflow: hidden;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.1);
}

div#slogan-sales p.interferer strong {
    font-size: 22px;
}

div#slogan-sales div.infobox.panel.panel-default {
    margin-top: 50px;
}

div#slogan-sales div.infobox.panel.panel-default div.panel-heading {
    background: none;
}

div#slogan-sales div.infobox.panel.panel-default div.panel-heading h3 {
    font-size: $font-size-h3 !important;
    color: $gray-base;
}

div#slogan-sales div.infobox.panel.panel-default div.panel-heading i {
    font-size: $font-size-h1;
    margin-right: 8px;
    float: left;
    color: $brand-primary;
}

div#slogan-sales div.infobox.panel.panel-default div.panel-body li:last-child {
    margin: 0;
}

div#slogan-sales div.infobox.panel.panel-default div.panel-body img {
    height: 20px;
    float: right;
    margin-top: -20px;
}

div#slogan-sales div.infobox div.panel-body ul,
div.infobox div.panel-body ol,
{
    padding-left: 28px;
}


/* testimonials */

div#content div.card.sales .img-circle {
    max-width: 125px;
    border: 1px solid $gray-lighter;
    margin: $grid-gutter-width-half 0 !important;
    border-radius: 50%;
}

div#content div.card.sales p {
    font-style: italic;
}

div#content .text-xs-center {
    text-align: center;
}


/* Modal */

.modal-body img {
    margin-bottom: $grid-gutter-width-half;
    margin-left: auto;
    margin-right: auto;
    max-height: 100px;
}

.modal-body p,
.modal-body small {
    text-align: center;
}

.modal-body p small {
    line-height: 1.3;
    display: inline-block;
}


/* Very small devices (mobile portrait) */

@media (max-width: $screen-xs) {
    div#keyvisual-sales {
        margin-top: 41px;
    }
    div#slogan-sales {
        text-align: center;
    }
    div#slogan-sales p {
        display: inherit;
        margin-bottom: 0px;
        margin-top: 100px;
        border-left: 0;
    }
    div#slogan-sales span {
        display: inherit;
        margin-bottom: 65px;
        border-left: 0;
        padding-left: 11px;
        padding-top: 0px;
        margin-bottom: 100px;
    }
    div#slogan-sales div.infobox.panel.panel-default {
        margin-bottom: 0px;
    }
    div#slogan-sales div.infobox.panel.panel-default div.panel-body img {
        margin-top: 0px;
    }
}

@media (min-width: $screen-sm) {
    .modal-body p,
    .modal-body small {
        text-align: left;
    }
    .modal-body img {
        max-height: inherit;
    }
}


/* Medium devices (desktops, 992px and up) */

@media (min-width: $screen-md) {
    div#keyvisual-sales {
        background: url("/img/key-visual-sales.jpg") no-repeat 0px 0px;
        background-position: center;
        background-size: cover;
    }
    div#slogan-sales p {
        font-size: 30px;
        margin: 0px 0 10px 0;
        margin-top: 100px;
    }
    div#slogan-sales span {
        font-size: 24px;
        margin: 0px 0 100px 0;
    }
    .navbar-sales div#logo img {
        height: 40px;
        margin-top: 0;
        text-align: left;
    }
    div#slogan-sales div.infobox.panel.panel-default {
        margin-bottom: 70px;
    }
    div#slogan-sales p {
        margin-top: 70px;
    }
    div#slogan-sales span {
        margin-bottom: 70px;
    }
    /* Card-Deck */
    div#content .card.sales {
        display: table-cell;
        margin-bottom: 0px;
    }
    div#content .sales.card-half {
        width: 50%;
    }
    div#content .sales.card-third {
        width: 33%;
    }
    div#content .sales.card-quarter {
        width: 25%;
    }
}

@media (min-width: $screen-lg) {
    div#slogan-sales p {
        width: auto;
    }
}
