/* Styled Lists */

div#content div.checklist-card p {
    margin-bottom: 0px;
}

div#content div.checklist-card div.panel-heading {
    border-bottom: 1px solid $gray-light;
}

div#content div.checklist-card div.panel-heading h3 {
    font-size: $font-size-h3 !important;
}

div#content div.checklist-card ul,
div#content div.checklist-card ol {
    margin-bottom: 0px;
    padding-left: 0;
}

div#content div.checklist-card li {
    border-right-width: 0;
    border-left-width: 0;
    padding: 4px 15px 10px 44px;
}

div#content div.checklist-card li.list-group-item i:first-child {
    color: $brand-primary;
    font-size: 20px;
    margin-right: 10px;
    margin-left: -28px;
}

div#content div.checklist-card ol li,
div#content div.checklist-card ul li {
    padding-top: 10px;
}

div#content ul.icon-ul li i {
    line-height: inherit;
}


/* Experten-Box */

div#content div.expert-box {
    background-color: $gray-lightest;
    padding: $table-cell-padding * 2;
    border: 1px solid $gray-light;
    font-size: $font-size-small;
}

div#content div.expert-box img {
    margin-left: 8px;
    width: 70px;
}

div#content div.expert-box h3 {
    color: $gray-base;
    padding-bottom: $table-cell-padding;
    border-bottom: 1px solid $gray-light;
    margin-bottom: 10px;
    margin-top: 0px;
}

div#content div.expert-box h6 {
    font-weight: 400;
    color: $gray-base;
    text-align: center;
    margin-left: 8px;
}

div#content div.expert-box i {
    font-size: $font-size-h1;
    margin-right: 8px;
    float: left;
    color: $brand-primary;
}

div#content div.expert-box .media-body {
    padding-right: 18px;
    border-right: 1px solid $gray-light;
}

div#sidebar div.expert-box .media-body {
    float: left;
    padding-right: 0px;
    padding-bottom: 10px;
    border-right: 0px;
    border-bottom: 1px solid $gray-light;
    margin-bottom: 10px;
    width: 100%;
}

div#sidebar div.expert-box .media-right {
    float: left;
    padding-left: 0px;
}

div#sidebar div.expert-box .media-right img {
    margin-left: 0px;
    width: 50px;
    float: left;
}

div#sidebar div.expert-box .media-right h6 {
    float: left;
    margin-top: $grid-gutter-width-half;
    margin-left: $grid-gutter-width-half;
}


/* Autorenbox */

div#content div.author-box-main h2,
div#content div.author-box-main h3,
div#content div.author-box-main h4, {
    margin-top: 0px;
}

div#content div.author-box-main {
    background-color: $gray-lightest;
    padding: $table-cell-padding * 2;
    border-top: 3px solid $brand-primary;
    font-size: $font-size-h5;
}

div#content div.author-box-main img {
    margin-right: 8px;
    width: 100px;
}

div#content div.author-box-main h2 {
    font-weight: 400;
    color: $brand-primary;
    padding-bottom: $table-cell-padding;
    border-bottom: 1px solid $gray-light;
    margin-bottom: 10px;
    margin-top: 0px;
}

div#sidebar div.author-box-main img {
    margin-right: 8px;
    width: 50px;
}

div#sidebar div.author-box-main h2 {
    font-size: $font-size-base;
    padding-bottom: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-bottom: 0px;
}


/* Infobox */

div#content div.infobox a {
    text-decoration: underline;
}

div#content div.infobox.panel.panel-default div.panel-heading {
    background: none;
}

div#content div.infobox.panel.panel-default div.panel-heading h3 {
    font-size: $font-size-h3 !important;
    color: $gray-base;
}

div#content div.infobox.panel.panel-default div.panel-heading i {
    font-size: $font-size-h1;
    margin-right: 8px;
    float: left;
    color: $brand-primary;
}

div#content div.infobox.panel.panel-info {
    border-color: $gray-light;
}

div#content div.infobox.panel.panel-info div.panel-heading {
    border-bottom: 1px solid $brand-primary;
    background: $brand-primary;
    border-radius: 3px 3px 0 0;
}

div#content div.infobox.panel.panel-info div.panel-heading h3 {
    font-size: $font-size-h3 !important;
    color: $component-active-color;
}

div#content div.infobox.panel.panel-info div.panel-heading i {
    font-size: $font-size-h1;
    margin-right: 8px;
    float: left;
    color: $component-active-color;
}

div#content div.infobox.panel.panel-primary div.panel-heading {
    border-bottom: 1px solid #fff;
    background: $brand-primary;
}

div#content div.infobox.panel.panel-primary div.panel-heading h3 {
    font-size: $font-size-h3 !important;
    color: $component-active-color;
}

div#content div.infobox.panel.panel-primary div.panel-heading i {
    font-size: $font-size-h1;
    margin-right: 8px;
    float: left;
    color: #ffffff;
}

div#content div.infobox.panel.panel-default div.panel-body li:last-child {
    margin: 0;
}

div#content div.infobox.panel.panel-default div.panel-body address:last-child {
    margin: 0;
}

div#content div.infobox.panel.panel-primary div.panel-body {
    background: $brand-primary;
    color: $component-active-color;
}

div#content div.infobox div.panel-body dl.dl-horizontal {
    font-size: $font-size-small;
    margin-bottom: 10px;
    margin-top: 10px;
}

div#content div.infobox div.panel-body dl.dl-horizontal dt {
    width: auto;
}

div#content div.infobox div.panel-body dl.dl-horizontal dd {
    margin-left: 60%;
    padding: 0;
    border: 0;
    margin-bottom: 5px;
}

div#content div.infobox div.panel-body dl.dl-horizontal:last-child {
    margin-bottom: 0;
}

div#content div.infobox div.panel-body p:last-child {
    margin-bottom: 0;
}


/* Card-Deck */

div#content .card {
    width: 100%;
    vertical-align: top;
    box-sizing: border-box;
    border: 1px solid $gray-light;
    margin: 0px 0px 20px 0px;
    position: relative;
    border-radius: 3px;
}

div#content .card-deck {
    border-spacing: $grid-gutter-width-half 0;
    height: 100%;
    margin-bottom: 0px;
}

div#content .card-wrapper {
    height: 100%;
    position: relative;
}

div#content .card-wrapper > hr {
    display: block;
    margin-top: 0;
    margin-bottom: $grid-gutter-width-lg;
    border: 0;
}

div#content .card .card-image {
    background-color: #ffffff;
    text-align: center;
    border-bottom: 3px solid $brand-primary;
}

div#content .card .card-image img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0px !important;
    border-radius: 3px 3px 0 0;
}

div#content .card .card-image i {
    padding: $grid-gutter-width-lg 0px $grid-gutter-width-lg 0px;
    color: $brand-primary;
}

div#content .card .card-content {
    padding: 8px 16px 8px 16px;
}

div#content .card-reverse {
    color: #fff;
}

div#content .card .card-content h2,
div#content .card .card-content h3,
div#content .card .card-content h4 {
    margin: 8px 0px 8px 0px;
}

div#content .card .card-content a {
    color: $brand-primary;
}

div#content .card .card-content a:hover {
    text-decoration: none;
    color: $gray-base;
}

div#content .card .card-content p {
    margin: 8px 0px 8px 0px;
    color: inherit;
}

div#content .card a.card-action {
    background-color: $gray-lightest;
    border-top: 1px solid $gray-light;
    padding: 8px 16px 8px 16px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

div#content .card a.card-action i {
    color: $brand-primary;
}

div#content .card a.card-action.btn,
div#content .card a.card-action.btn:hover,
div#content .card a.card-action.btn i,
div#content .card a.card-action:hover i {
    color: #fff;
}

div#content .card a.card-action.btn {
    background-color: $brand-secondary;
    box-shadow: none;
    border: 0;
    border-radius: 0 0 3px 3px;
}

div#content .card a.card-action.btn:hover {
    background-color: $brand-secondary-dark;
}

div#content .card a.card-action.btn:hover i {
    color: #fff !important;
}

div#content .card a.card-action i:hover {
    color: $gray-base;
}

div#content .card a.card-action:hover i {
    color: $gray-base;
}

div#content .card a.card-action {
    color: $brand-primary;
}

div#content .card a.card-action:hover {
    color: $gray-base;
    text-decoration: none;
}

div#content .card .list-group {
    margin: 0px;
    padding: 0px;
    box-shadow: none;
    border-radius: 0px;
}

div#content .card .list-group-item {
    margin: 0px;
    padding: 8px 16px 8px $grid-gutter-width-lg;
    background-color: $component-active-color;
    border-top: 1px solid $gray-light !important;
    border-right: 0px !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
    text-indent: -12px;
}

div#content .card .list-group-item:last-child {
    border-bottom: 0px;
}

div#content .card .list-group-item i {
    width: 12px;
    margin-right: 0;
    float: none;
    color: inherit;
    font-size: inherit;
}


/* CTA-Box */

div#content div.cta-box.panel.panel-info div.panel-heading {
    border-bottom: 1px solid $gray-light;
    background: none;
    border-radius: 3px 3px 0 0;
    filter: none;
}

div#content .cta-box .btn {
    width: 100%;
}

div#content div.cta-box.panel.panel-info div.panel-heading h3 {
    font-size: $font-size-h3 !important;
    color: $gray-base;
}

div#content div.cta-box.panel.panel-info div.panel-heading i {
    font-size: $font-size-h1;
    margin-right: 8px;
    float: left;
    color: $brand-primary;
}

div#content .cta-box p {
    margin-bottom: $grid-gutter-width-half;
}


/* Partner-Box */

div#content .partner {
    padding-left: $grid-gutter-width-half;
    padding-right: $grid-gutter-width-half;
}

div#content .partner.card-bg-default {
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
}

div#content .partner img {
    width: 100%;
}

div#content .partner img:last-child {
    margin-bottom: 0px;
}

div#content .partner-field {
    padding: 0px !important;
    border-right: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
}


/* small devices (mobile portrait) */

@media (min-width: $screen-sm) and (max-width: $screen-md),
(min-width: $screen-lg) {
    /* Card-Deck */
    div#content .card {
        display: table-cell;
        margin-bottom: 0px;
    }
    div#content .card-half {
        width: 50%;
    }
    div#content .card-third {
        width: 33%;
    }
    div#content .card-quarter {
        width: 25%;
    }
    div#content .card-deck {
        display: table;
        table-layout: fixed;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 20px;
    }
    div#content .card-deck-wrapper {
        display: table-row;
    }
}


/* Very small devices (mobile portrait) */

@media (max-width: $screen-xs) {
    div#content div.author-box-main img {
        width: 70px;
    }
    div#content .partner-field {
        width: 50%;
    }
}
