div#content div.posts-date {
    z-index: 1;
}

div#content div.posts-date p {
    background-color: $gray-light;
    padding: 5px 0;
    color: $gray-base;
    border-radius: 3px;
    text-align: center;
    margin-bottom: 10px;
}

div#content div.posts-content h2 {
    margin-top: 0px;
}

div#content div.posts-content {
    padding-bottom: 10px;
}

div#content div.posts-content i {
    margin-left: 10px;
}

div#content div.posts-content a {
    text-decoration: none;
}

div#content div.posts-content hr {
    margin-left: $grid-gutter-width-half;
    margin-right: $grid-gutter-width-half;
}

div#content div.timeline {
    background-color: $gray-light;
    position: absolute;
    top: 0;
    left: -64px;
    width: 1px;
    height: 100%;
}

div#content div.posts .media {
    margin-top: 0;
    margin-bottom: 10.5px;
}

div#content div.posts .media-body {
    vertical-align: middle;
    font-weight: 600;
}

div#content div.posts .media-object {
    height: 40px;
}




/* Small devices (tablets, 768px and up) */

@media (min-width: $screen-sm) {
    div#content div.posts-date p {
        padding: 10px 0;
    }
        div#content div.posts-date p span {
        font-weight: 600;
    }
}


/* Medium devices (desktops, 992px and up) */

@media (min-width: $screen-md) {
    div#content div.timeline {
        left: -54px;
    }
}


/* Large devices (large desktops, 1200px and up) */

@media (min-width: $screen-lg) {
    div#content div.timeline {
        left: -66px;
    }
}
