/* Import project bootstrap / theme variables */

@import "project-variables";
@import "iconfont";

/* Load theme */

@import "theme";

div#keyvisual,
div#keyvisual-sales {
    background-position: 0px -52px;
}

div#slogan p {
    margin-top: 100px;
}

div#slogan-sales p {
    margin-top: 150px;
}

div#slogan span,
div#slogan-sales span {
    margin-bottom: 50px;
}

@media (max-width: $screen-xs) {
    div#slogan p,
    div#slogan-sales p {
        margin-top: 175px;
    }
    div#slogan span,
    div#slogan-sales span {
        margin-bottom: 15px;
    }
    div#keyvisual,
    div#keyvisual-sales {
        background-position: -230px -52px;
    }
}


/* Small devices (tablets, 768px and up) */

@media (min-width: $screen-sm) {
    div#map h3 {
        color: #ffffff !important;
    }
    div#slogan p,
    div#slogan-sales p {
        margin-top: 150px;
    }
}


/* Medium devices (desktops, 992px and up) */

@media (min-width: $screen-md) {
    div#slogan p {
        margin-top: 300px;
    }
    div#lead.subpage div#slogan p {
        margin-top: 85px;
    }
    div#slogan-sales p {
        margin-top: 100px;
    }
    div#slogan-sales span {
        margin-bottom: 20pxpx;
    }
    div#keyvisual,
    div#keyvisual-sales {
        background-position: center;
    }
}


/* Large devices (large desktops, 1200px and up) */

@media (min-width: $screen-lg) {
    div#slogan p,
    div#slogan-sales p {
        width: auto;
    }
    div#keyvisual-sales {
        background-position: center -35px;
    }
}
