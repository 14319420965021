/* Content General styles */

div#content h1 {
    font-weight: 400;
}

div#content h3 {
    font-weight: 600;
}

div#content h1,
div#content h2,
div#content h3,
div#content h4 {
    margin-top: $grid-gutter-width;
}

div#sidebar h1,
div#sidebar h2,
div#sidebar h3,
div#sidebar h4 {
    margin-top: 0;
}

div#sidebar h2 {
    font-size: $font-size-base;
}

div#sidebar h3 {
    font-weight: 500;
}

div#content p {
    margin-bottom: $font-size-base + 6px;
}

div#content p a {
    text-decoration: underline;
}

div#content li a {
    text-decoration: underline;
}

div#content hr {
    clear: left;
    border-top: 1px solid $gray-light;
}

div#content hr.invisible {
    color: $content-bg;
    border-color: $content-bg;
}

div#content i.active {
    font-size: 25px;
    color: $brand-primary;
}

div#content i.inactive {
    font-size: 25px;
    color: $gray-base;
}

div#content .float-half,
div#content .float-third,
div#content .float-quarter {
    float: left;
    margin: 5px $grid-gutter-width-half 10px 0;
}

div#content .float-full {
    width: 100%;
    margin: 0px 0 20px 0;
}

div#sidebar .float-full {
    margin: 0px 0 $grid-gutter-width-lg 0;
}

div#content .float-half {
    width: 50%;
}

div#content .float-third {
    width: 50%;
}

div#content .float-quarter {
    width: 25%;
}

div#content .card-bg {
    background: #fff !important;
    border-radius: $card-border-radius !important;
    box-shadow: $card-box-shadow !important;
    border: $card-border !important;
}

div#content .card-bg-default {
    background: #fff !important;
    border-radius: $card-border-radius !important;
    border: $card-border !important;
}

div#content .card-bg-active {
    background: $brand-primary !important;
    box-shadow: $card-box-shadow !important;
    border-radius: $card-border-radius !important;
    border: $card-border !important;
}


/* Images */

div#content img.img-responsive {
    margin-bottom: $font-size-base;
}

div#content figure.img-caption {
    background-color: #fff;
    outline: 1px solid $gray-light;
    border-radius: 3px;
}

div#content figure.img-caption img {
    width: 100%;
    border-bottom: 1px solid $gray-light;
    border-radius: 3px 3px 0 0;
}

div#content figure.img-caption small {
    display: block;
    padding: $padding-small-vertical $padding-small-horizontal;
}


/* List styles */

div#content ul,
div#content ol {
    margin-bottom: $font-size-base + 6px;
    padding-left: 29px;
}

div#content div#main > ul,
div#content div#main > ol {
    padding-left: 0;
}

div#content ul li,
div#content ol li {
    margin-bottom: 13px;
}

div#content div#main > ul li,
div#content div#main > ol li {
    position: relative;
    left: 2em;
    margin-right: 2em;
}

div#content ul.icon-ul {
    margin-left: $font-size-base + 6px;
    margin-bottom: 0;
    padding-left: 0;
}

div#content ul.icon-ul li:last-child {
    margin-bottom: 0;
}

div#content ul.icon-ul li i {
    font-size: $font-size-base;
}

div#content ul li.list-group-item,
div#content ol li.list-group-item {
    background-color: #ffffff;
}

div#content ul li.list-group-item,
div#content ol li.list-group-item {
    margin-bottom: -1px;
}

div#content ul.list-group {
    padding: 0;
    box-shadow: none;
    border-radius: $card-border-radius;
}

div#content li.list-group-item.header {
    background-color: $content-bg;
}

div#content li.list-group-item.header h3 {
    margin-bottom: 0;
}

div#content li.list-group-item i {
    float: right;
    font-size: $font-size-h1;
    color: $brand-primary;
}

div#content li.list-group-item.deactivated i {
    color: $gray-light;
}

ul.ul-styled li {
    @include icon(check-circle);
    list-style-type: none;
    line-height: 1.3;
}

ul.ul-styled li::before {
    margin-right: 9px;
    color: $brand-primary;
    font-size: 20px;
    top: 2px;
    position: relative;
    margin-left: -29px;
}

ol.ol-styled {
    counter-reset: li;
    list-style: none;
    *list-style: decimal;
}

ol.ol-styled li {
    line-height: 1.8;
}

ol.ol-styled li::before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    background: $brand-primary;
    height: 18px;
    width: 18px;
    text-align: center;
    border-radius: 2em;
    margin-left: -28px;
    color: #fff;
    font-size: 11px;
    line-height: 1.7;
    font-weight: 600;
    margin-top: 3px;
}


/* Pagination */

div#content ul.pagination {
    padding-left: 0;
    margin-top: 0;
}

div#content ul.pagination li a {
    padding: 3px 12px;
    color: $brand-primary;
}

div#content .pagination>li>a:hover,
.pagination>li>a:focus,
.pagination>li>span:hover,
.pagination>li>span:focus {
    color: $gray-base;
    background-color: $gray-lightest;
}

div#content ul.pagination .active a:hover {
    color: #fff;
    background-color: $brand-primary;
}

div#content ul.pagination .active a {
    color: #fff;
}

div#content small.page_count {
    text-align: left;
    margin: 8px 0;
    display: block;
}


/* Definition Lists */

dd {
    margin-left: 0;
    margin-bottom: $grid-gutter-width-half;
    padding-left: $grid-gutter-width-half;
    padding-bottom: $grid-gutter-width-half;
    border-bottom: 1px solid $gray-light;
}

div#content ul.definition-list-horizontal {
    margin-top: 0;
    margin-bottom: 20px;
    padding-left: 0;
}

div#content ul.definition-list-horizontal li {
    list-style-type: none;
    margin-bottom: $grid-gutter-width-half;
}

div#content ul.definition-list-horizontal li.striped {
    border-bottom: 1px solid $gray-light;
}

div#content ul.definition-list-horizontal li.striped:last-child {
    border-bottom: 0px;
}

div#content ul.definition-list-horizontal li ul {
    padding-left: 0;
}

div#content ul.definition-list-horizontal h4 {
    overflow: hidden;
    clear: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    margin-bottom: 5px;
}

div#content ul.definition-list-horizontal p {
    margin-bottom: 15px;
}

div#content ul.definition-list-horizontal hr {
    margin-top: 10px;
    margin-bottom: 17px;
}


/* Table styles */

div#content table {
    border: 0;
    margin-bottom: 0px;
}

div#content table.table-fixed {
    table-layout: fixed;
}

div#content table.table-stretched th,
div#content table.table-stretched td {
    padding: ceil($table-cell-padding * 1.5);
}

div#content table.table-nowrap th,
div#content table.table-nowrap td {
    white-space: nowrap;
}

div#content table.table-nowrap th.wrap,
div#content table.table-nowrap td.wrap {
    white-space: normal;
}

div#content table thead th {
    border-bottom-width: 0;
    font-weight: 600;
}

div#content table thead th a,
div#content table thead th a:hover,
div#content table thead th a:active,
div#content table thead th a:visited {
    color: #fff;
}

div#content table thead td {
    border-left: 0;
    border-bottom: 0;
}

div#content table tbody,
div#content table tfoot {
    font-size: $font-size-small;
}

div#content table tfoot {
    background-color: $gray-lightest;
    border-radius: 0 0 3px 3px;
}

div#content table tbody {
    background-color: none;
}

.table>thead>tr>th,
.table>thead>tr>td,
.table>tbody>tr>th,
.table>tbody>tr>td,
.table>tfoot>tr>th,
.table>tfoot>tr>td {
    padding: 10px;
}

div#content table tfoot td,
div#content table tfoot th {
    background-color: $gray-lightest;
}

div#content table ul {
    font-size: $font-size-small;
}


/* Panel Styles */

div#content div.panel {
    box-shadow: none;
    border: 0;
}

div#content div.panel-heading h3 {
    margin-top: 0px;
}

div#content div.panel.panel-default div.panel-heading {
    border-bottom: 1px solid $gray-light;
    background: $gray-lighter;
    filter: none;
}

div#content div.panel.panel-default div.panel-heading h3 {
    font-size: $font-size-base;
    font-weight: 600;
}

div#content div.panel div.panel-body {
    font-size: $font-size-small;
}

div#content div.panel div.panel-body ol:last-child,
div#content div.panel div.panel-body ul:last-child {
    margin-bottom: 0px;
}

div#content div.panel img {
    width: 100%;
    border-bottom: 1px solid $gray-light;
}

div#content div.panel div.panel-body hr {
    margin: 0;
    border-color: $gray-light;
}

div#content div.panel div.panel-body blockquote {
    margin: 0;
}

div#sidebar div.panel-group div.panel {
    margin-bottom: 0;
    margin-top: 0;
    background: #fff;
    border-radius: 0px;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
}

div#sidebar div.panel-group div.panel:last-child {
    border-bottom: 0px;
}


/* Wells */

div#content div.well {
    border-radius: 0;
    font-size: $font-size-small;
    background: $gray-lightest;
    box-shadow: none;
}


/* Social Icons */

div.btn-social-icons {
    border-radius: 0;
    margin-top: $grid-gutter-width;
}

div.btn-social-icons a {
    font-size: $font-size-base;
    text-transform: uppercase;
    padding: 5px;
    border-radius: 0;
    transition: background 200ms ease-in-out;
}

.btn-facebook {
    color: #fff;
    background-color: #3B5998;
    border-color: #3B5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active {
    color: #fff;
    background-color: #133783 !important;
    border-color: #133783 !important;
}

.btn-twitter {
    color: #fff;
    background-color: #55ACEE;
    border-color: #55ACEE;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active {
    color: #fff;
    background-color: #3B88C3 !important;
    border-color: #3B88C3 !important;
}

.btn-google {
    color: #fff;
    background-color: #D73D32;
    border-color: #D73D32;
}

.btn-google:hover,
.btn-google:focus,
.btn-google:active {
    color: #fff;
    background-color: #E74B37 !important;
    border-color: #E74B37 !important;
}

.btn-mail {
    color: #fff;
    background-color: #afafaf;
    border-color: #afafaf;
}

.btn-mail:hover,
.btn-mail:focus,
.btn-mail:active {
    color: #fff;
    background-color: #9b9b9b !important;
    border-color: #9b9b9b !important;
}


/* FAQ-Teaser */

div#content div.faq-box {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

div#content div.faq-box div.panel {
    background: none;
}

div#content div.faq-box div.panel div.panel-heading {
    background: none;
    padding: 5px $grid-gutter-width-half;
}

div#content div.faq-box div.panel.panel-link div.panel-heading {
    background: $gray-lightest;
    border-radius: 0 0 3px 3px;
}

div#content div.faq-box div.panel.panel-link div.panel-heading button {
    display: block;
}

div#content div.faq-box div.panel div.panel-heading h3 {
    font-size: $font-size-small;
    line-height: $font-size-small + 2px;
    font-weight: 500;
    font-family: $font-family-sans-serif;
}

div#content div.faq-box div.panel div.panel-heading h3 button {
    display: block;
    border: 0;
    padding: 0;
    text-align: left;
    background-color: transparent;
    width: 100%;
}

div#content div.faq-box div.panel div.panel-heading h3 button span {
    display: table-cell;
    vertical-align: middle;
    height: $grid-gutter-width-lg;
}

div#content div.faq-box div.panel.panel-default div.panel-heading h3 button i {
    color: $brand-primary;
    padding: 9px 0px;
    margin-right: $grid-gutter-width-half;
    float: left;
    font-size: $font-size-h1;
}

div#content div.faq-box div.panel.panel-default div.panel-heading .icon-minus:before {
    color: #ccc;
}

div#content div.faq-box div.panel div.panel-body {
    background-color: #fcfcfc;
    border-top: 0;
    border-bottom: 1px solid $gray-light;
}

div#content div.faq-box div.panel.panel-link div.panel-heading i {
    margin-left: 5px;
}

div#content div.faq-box div.panel.panel-link h3 {
    font-size: $font-size-small;
    line-height: $font-size-small + 2px;
    font-weight: 500;
    font-family: $font-family-sans-serif;
    margin-bottom: 0;
    color: inherit;
}

div#sidebar .list-unstyled.faq-linklist {
    padding-left: 20px;
}

div#sidebar .list-unstyled.faq-linklist i {
    position: absolute;
    left: 14px;
    margin-top: 4px;
}


/* trust-quote */

div.trust-quote {
    background: $gray-lightest;
    border: 1px solid $gray-light;
}

div.trust-quote img {
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid $gray-light;
}

div.trust-quote blockquote p,
div.trust-quote blockquote cite {
    font-size: $font-size-small;
}

div.trust-quote blockquote p {
    font-style: italic;
}

div.trust-quote blockquote {
    padding: 0 20px;
    margin: 0 20px 20px 20px;
}


/* inline-quote */

div#content div.inline-quote blockquote {
    padding: 0px 5px 0px 21px;
    margin: 0 0 0px;
    font-size: 17.5px;
    border-left: 5px solid $brand-primary;
}

div#content div.inline-quote blockquote p {
    font-size: $font-size-h3;
    font-family: $headings-font-family;
}

div#content div.inline-quote blockquote footer {
    font-size: 67%;
    color: $gray-base;
    padding: 0;
    line-height: 1;
}

div#content div.inline-quote blockquote footer:before {
    content: '';
}


/* tooltips */

a.tooltip_link {
    position: absolute;
    border: 2px solid $brand-primary;
    border-radius: 13px;
    padding: 1px 9px;
    background-color: #fff;
    font-size: $font-size-small;
}

div.tooltip-inner {
    border: 1px solid $brand-primary;
    border-radius: 0;
    box-shadow: 3px 3px 10px 1px $gray-base;
    text-align: left;
    padding: $table-cell-padding;
}


/* ekomi */

div#sidebar div#ekomi .rate-spread {
    display: none;
}

div#sidebar div#ekomi ul li {
    padding-left: 4px;
    padding-right: 4px;
}


/* Very small devices (mobile portrait) */

@media (max-width: $screen-xs) {
    div#content .float-quarter {
        width: 50%;
    }
    div#content .float-third,
    div#content .float-half {
        width: 100%;
        margin-bottom: 20px;
    }
    div#content .partner-field {
        width: 100%;
    }
}


/* Small devices (tablets, 768px and up) */

@media (min-width: $screen-sm) {
    div#content ul.definition-list-horizontal h4 {
        float: left;
        width: 35%;
    }
    div#content ul.definition-list-horizontal p {
        margin-left: 35%;
    }
    div#content ul.definition-list-horizontal li ul {
        padding-left: 35%;
    }
    div#content small.page_count {
        text-align: right;
    }
}


/* Medium devices (desktops, 992px and up) */

@media (min-width: $screen-md) {
    div#sidebar div#ekomi img {
        height: 50px;
        margin-right: 6px;
    }
    div#sidebar div#ekomi .ekomi-stars i.icon-star {
        font-size: 24px;
    }
    div#sidebar div#ekomi span.label {
        margin-top: 5px;
        float: right;
    }
    div#sidebar div#ekomi .average {
        font-size: 24px;
    }
}


/* Large devices (large desktops, 1200px and up) */

@media (min-width: $screen-lg) {
    div#content .float-third {
        width: 33%;
    }
    div#sidebar div#ekomi img {
        height: 72px;
        margin-right: 13px;
    }
    div#sidebar div#ekomi .ekomi-stars i.icon-star {
        font-size: 24px;
    }
    div#sidebar div#ekomi .ekomi-stars ul.list-inline {
        margin-top: 14px;
    }
    div#sidebar div#ekomi span.label {
        margin-top: 5px;
        float: right;
    }
    div#sidebar div#ekomi .average {
        font-size: 2em;
    }
}
