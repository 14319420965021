/* lead form */

#form-close {
    margin-top: ($grid-gutter-width);
    padding: 0;
}

#form-close h3 {
    margin-top: 0px;
}

div#form-header {
    background-color: $brand-primary;
    border-radius: 0px;
    z-index: 1;
}

div#form-header span {
    font-family: $headings-font-family;
    display: block;
    color: #fff;
    font-weight: 400;
    font-size: $font-size-h2;
    padding-top: 11px;
    padding-bottom: 5px;
    text-transform: uppercase;
    margin: 0;
}

div#form-header p {
    color: #fff;
}

div.siegel p {
    height: 93px;
    width: 93px;
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: -25px;
    border-radius: 50px;
    border: 2px solid #fff;
    text-transform: uppercase;
    padding: 26px 5px 17px 6px;
    background-color: $brand-secondary;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
    font-size: $font-size-h3;
    line-height: 1.3;
    overflow: hidden;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.1);
}

div.siegel p span {
    font-weight: 600;
}

div.form-label.top div.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 14px 0 14px;
    border-color: $brand-primary transparent transparent transparent;
    margin-left: $grid-gutter-width-half;
    margin-bottom: -13px;
}

div#form-body {
    background-color: $gray-lightest;
    padding: 0;
}

div#form-success {
    background-color: #fff;
}

div#form-body .form-control {
    padding: 6px;
    height: 36px;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

div#form-body .has-error .form-control::-moz-placeholder {
    color: $state-danger-text;
}

div#form-body .has-error .form-control:-ms-input-placeholder {
    color: $state-danger-text;
}

div#form-body .has-error .form-control::-webkit-input-placeholder {
    color: $state-danger-text;
}

div#form-body div.form-label {
    background-color: $gray-lightest;
    /*border-top: 1px solid $gray-light;*/
    margin: 0 0 $grid-gutter-width-half 0;
}

div#form-body div.item h5:last-child {
    border-top: 1px solid $gray-light;
}

div#form-body div.form-label.top {
    margin-top: 0;
}

div#form-body div.form-label label {
    font-family: $headings-font-family;
    font-weight:400;
    font-size:15px;
    margin:0;
    padding: $grid-gutter-width-half;
    display: block;
}

div#form-body div.item {
    /*min-height: 360px;*/
    background-color: #fff
}

div#form-body div.item div.form-element {
    padding: 0 $grid-gutter-width-half 0 $grid-gutter-width-half;
}

div#form-body div.icon {
    max-width: 111px;
    margin-bottom: $grid-gutter-width-half;
}

div#form-body div.icon i {
    font-size: 39px;
    color: $brand-primary;
}

div#form-body div.icon div {
    width: 81px;
    height: 81px;
    border: 2px solid $gray-lighter;
    border-radius: 10px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

div#form-body input[type=text],
div#form-body input[type=email],
div#form-body input[type=tel] {
    width: 100%;
}

div#form-body input[type=radio] {
    margin-right: 5px !important;
}

div#form-body .checkbox,
div#form-body .radio {
    margin-top: 0;
}

div#form-slide-control {
    padding-bottom: $grid-gutter-width-half;
}

div.form-slide-back {
    padding-bottom: $grid-gutter-width-half;
}

div#form-body div.carousel-controls {
    margin: 0;
    padding: 0 0 0 0;
    border-top: 1px solid $gray-light;
}

div#form-slide-control .btn,
div.form-slide-back .btn,
div#form-close .btn,
div#address .btn {
    width: 100%;
    border-radius: 4px;
}

div.form-slide-back .btn {
    max-width:90px;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
    border:1px solid #ddd;
}

div#form-slide-control .btn,div.form-slide-back .btn, {
    margin-top: $grid-gutter-width-half;
}

div#address {
    padding: 0 $grid-gutter-width-half 0 $grid-gutter-width-half;
}

div#address div.form-group input[type=radio]:last-child {
    margin-left: 10px;
}

div#address .btn {
    margin-bottom: $grid-gutter-width-half;
}

div#form-body div.security {
    font-size: $font-size-h5;
    border-top: 1px solid $gray-light;
    padding: 10px $grid-gutter-width-half;
    line-height: 1.4;
}

div#form-body div.security i {
    font-size: 33px;
    float: left;
    margin: 1px 12px 0 0;
}

div#form-body input.form-error {
    border: 1px solid $brand-danger;
}

div#form-body div.alert {
    border: 0;
    border-radius: 0;
    margin-top: -69px;
    background-image: none;
    text-shadow: none;
    box-shadow: none;
    margin-bottom: 0px;
}

/* country, zipcode, city */

div#form-body input.zipcode {
    width:27%;
    margin-right:3%;
    float:left;
}

div#form-body div.form-group-country input.zipcode {
    width:22%;
}

div#form-body input.city {
    width:70%;
}

div#form-body div.form-group-country input.city {
    width:53%;
}

div#form-body div.bootstrap-select {
    float:left;
    margin-right:3%;
}

div#form-body div.bootstrap-select button {
    background-color:#fff !important;
    background-image:none !important;
    height:36px;
    padding:6px 16px 6px 6px !important;
    border:1px solid #bbb !important;
    box-shadow: inset 2px 2px 5px rgba(0,0,0,0.1) !important;
    margin-top:0 !important;
    margin-bottom:0 !important;
}

div#form-body div.bootstrap-select button span.caret {
    position: absolute;
    right: 6px;
}

.btn-action i {
    color: #fff;
    margin-left: 5px;
}

.btn-default i {
    margin-right: 5px;
}

.btn-action {
    color: #fff;
    /*text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);*/
    background-color: $brand-secondary;
    *background-color: $brand-secondary;
    /*background-image: -o-linear-gradient(top, $brand-secondary-light, $brand-secondary);
    background-image: linear-gradient(to bottom, $brand-secondary-light, $brand-secondary);
    background-repeat: repeat-x;
    border-color: $brand-secondary;*/
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.btn-action:hover,
.btn-action:focus,
.btn-action:active,
.btn-action.active,
.btn-action.disabled,
.btn-action[disabled] {
    color: #fff;
    background-color: $brand-secondary-dark;
    *background-color: $brand-secondary-dark;
    /*background-image: -moz-linear-gradient(top, $brand-secondary, $brand-secondary-dark);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from($brand-secondary), to($brand-secondary-dark));
    background-image: -webkit-linear-gradient(top, $brand-secondary, $brand-secondary-dark);
    background-image: -o-linear-gradient(top, $brand-secondary, $brand-secondary-dark);
    background-image: linear-gradient(to bottom, $brand-secondary, $brand-secondary-dark);
    border-color: $brand-secondary-dark;*/
}

/* phone */
div#client-listing form.form-inline div.input-group input.form-control {
    height:37px;
}

div#form-success div.row:last-child {
    margin-bottom:$grid-gutter-width-half;
}

/* spinner */

div#spinner {
    position: absolute;
    text-align: center;
    display: none;
    width: 100%;
    top: 30%;
}

div#spinner i {
    color: $brand-primary;
}

div#spinner span {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.375;
    font-family: $headings-font-family;
}

div.fade-out {
    opacity: 0.05;
}


/* form result */

div#client-listing {
    padding: $grid-gutter-width-half 0 0 0;
}

div#client-listing form.form-inline input.form-control {
    height: 36px;
    width: 100%;
}

div#client-listing form.form-inline {
    margin-top: $grid-gutter-width-half;
}

div#form-success hr {
    margin: $grid-gutter-width-half 0 $grid-gutter-width-half 0;
}

div#client-listing h2 {
    margin-top: 0;
    width: auto !important;
}

div.client-element img {
    margin-bottom: 0;
    border-radius: 0;
    margin-right: $grid-gutter-width-half;
    width: 70px;
    height: 70px;
}

div.client-element p {
    margin-bottom: 0;
}

div.client-element address {
    font-size: $font-size-h5;
    margin-bottom: 0;
    margin-left: 85px;
}

div#form-success div.client-element:last-child {
    margin-bottom:$grid-gutter-width;
}

/* Modal */

div#lead div.modal-content {
    padding-bottom: $grid-gutter-width-lg;
}

div#lead div.modal-content h2 {
    font-size: $font-size-h2;
    margin: 21px 0 10.5px 0;
}


/* Very small devices (mobile portrait) */

@media (max-width: $screen-xs) {
    div#form-header span {
        text-transform: none;
        margin-bottom: 5px;
    }
    div#form-header p.claim {
        display: none;
    }
    div.form-element div.col-xs-6 {
        width: 100%;
    }
    div#form-close {
        box-shadow: none;
        margin-top: 45px;
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-md) {
    div#form {
        padding-left: $grid-gutter-width-half;
        padding-right: $grid-gutter-width-half;
        margin-top: $grid-gutter-width;
    }
}


/* Small devices (tablets, 768px and up) */

@media (min-width: $screen-sm) {
    div#form-close {
        padding: 0px;
        background-color: #ffffff;
        margin-right: 0px;
        border-radius: 4px;
        border-top: 3px solid $brand-primary;
        box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.2);
    }
    div#form-close .btn {
        font-size: $font-size-base;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
        width: 96%;
        margin: 0 $grid-gutter-width-half $grid-gutter-width-half $grid-gutter-width-half;
    }
    div#form-close h3 {
        border-radius: 4px 4px 0px 0px;
        color: $gray-base;
        padding: $grid-gutter-width-half;
        margin-bottom: $grid-gutter-width-half;
        border-bottom: 1px solid $gray-light
    }
    div#form-close p {
        margin-bottom: $grid-gutter-width-half;
        margin-left: $grid-gutter-width-half;
        margin-right: $grid-gutter-width-half;
    }
    div#form-close i {
        color: $brand-primary;
        margin-right: 10px;
        font-size: $font-size-h2;
    }
    div#form-body,
    div#form-success {
        border: 1px solid $gray-light;
        border-top: 0;
    }
    div#form-slide-control, div.form-slide-back {
        padding: 0 $grid-gutter-width-half 0 $grid-gutter-width-half;
    }
    div#form-body form.lead-form .btn {
        border-radius: 4px;
        padding: 10px 12px;
        font-size: $font-size-base;
        margin-bottom: $grid-gutter-width-half;
        background-image: none;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
        border: 1px solid transparent;
        text-shadow: none;
        line-height: 1.3333333;
    }
    div#form-body form.lead-form .btn-default {
        background-color: $gray-lightest;
        margin-top: $grid-gutter-width-half;
    }
    div#form-body form.lead-form .btn-default:hover {
        background-color: $gray-lighter;
        color: $gray-base;
    }
    div#form-body div.icon {
        border-right: 0px solid $gray-light;
    }
    div#client-listing form.form-inline div.form-group {
        width: 40%;
    }
    div#form-header {
        border-radius: 5px 5px 0px 0px;
    }
    div#slogan .container {
        padding-left: $grid-gutter-width-half !important;
        padding-right: $grid-gutter-width-half !important;
    }
}


/* Medium devices (desktops, 992px and up) */

@media (min-width: $screen-md) {
    div#form {
        margin: 20px $grid-gutter-width-half 20px 0px;
    }
    div#form > div.row {
        box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, .2);
        border-bottom: 4px solid $brand-primary;
        border-radius: 5px 5px 5px 5px;
    }
    div#form-close {
        margin-top: 85px;
        margin-bottom: 85px;
    }
    div#form-close .btn {
        width: 90%;
    }
    div#form-body {
        border: 0;
        min-height: 416px;
    }
    div#form div.carousel-controls {
        /*min-height: 50px;*/
        background-color: $gray-lightest;
    }
    div#address.b2c input#LeadStreet {
        margin-top: 37px;
    }
}


/* Large devices (large desktops, 1200px and up) */

@media (min-width: $screen-lg) {
    div.siegel p:after {
        margin-left: 0px;
    }
    div#form-body {
        border: 0;
        min-height: 402px;
    }
    div#form-slide-control .btn, div.form-slide-back .btn {
        margin-top: 18px;
    }
}
